/* Add your profile image here */
/* .Photo {
  background-image: url("https://images.pexels.com/photos/3799821/pexels-photo-3799821.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
} */
/* Projects Photo */
#project1 {
  background-image: url("../images/screenshot-SlavensRacing.png");
  cursor: none;
}
#project2 {
  background-image: url("../images/screenshot-BRP.png");
  cursor: none;
}
#project3 {
  background-image: url("../images/screenshot-ElineAccessories.png");
  cursor: none;
}
#project4 {
  background-image: url("../images/screenshot-ExcavatorTek.png");
  cursor: none;
}
#project5 {
  background-image: url("../images/screenshot-DSMH.png");
  cursor: none;
}
#project6 {
  background-image: url("../images/weatherdashboard-screenshot.jpg");
  cursor: none;
}
#project7 {
  background-image: url("../images/workout-screenshot.jpg");
  cursor: none;
}
#project8 {
  background-image: url("../images/digital_template_engine.jpg");
  cursor: none;
}
#project9 {
  background-image: url("../images/screenshot-LetsGetTrivial.png");
  cursor: none;
}
#project10 {
  background-image: url("../images/Craftsman\ Tool\ Packaging.png");
  cursor: none;
}
#project11 {
  background-image: url("../images/Ridgid\ Tool\ Packaging.png");
  cursor: none;
}
#project12 {
  background-image: url("../images/Slavens\ Racing\ Magazine\ Ads.png");
  cursor: none;
}
#project13 {
  background-image: url("../images/T\ Shirt\ Designs.png");
  cursor: none;
}
#project14 {
  background-image: url("../images/Motorworks\ Catalog\ Covers\ &\ Ads.png");
  cursor: none;
}
#project15 {
  background-image: url("../images/MotorWorks\ Magazine\ Ads.png");
  cursor: none;
}
#project16 {
  background-image: url("../images/Motorworks\ Catalog\ Inside\ Pages\ 2.png");
  cursor: none;
}
#project17 {
  background-image: url("../images/Motorworks\ Catalog\ Inside\ Pages.png");
  cursor: none;
}
#project18 {
  background-image: url("../images/Brochures\ &\ Business\ Cards.png");
  cursor: none;
}
#project19 {
  background-image: url("../images/Display\ Image\ Tradeshow\ Design.png");
  cursor: none;
}
#project20 {
  background-image: url("../images/UCCS.gif");
  cursor: none;
}
#project21 {
  background-image: url("../images/Tradeshow\ Design.png");
  cursor: none;
}
#project22 {
  background-image: url("../images/trade_show_banners.jpg");
  cursor: none;
}
#project23 {
  background-image: url("../images/trade_show_20foot.jpg");
  cursor: none;
}
#project24 {
  background-image: url("../images/trade_show_10foot_FabricPopup.jpg");
  cursor: none;
}
#project25 {
  background-image: url("../images/trade_show_10foot_Sterisil.jpg");
  cursor: none;
}
#project26 {
  background-image: url("../images/trade_show_table_top_COS.jpg");
  cursor: none;
}
#project27 {
  background-image: url("../images/trade_show_table_top_Goodrich.jpg");
  cursor: none;
}
#project28 {
  background-image: url("../images/UncleSamsPiggyBank.jpg");
  cursor: none;
}
/* Miscelaneous Activities photo */
#project29 {
  background-image: url("../images/art_EastCoastDeliMural.jpg");
  cursor: none;
}
#project30 {
  background-image: url("../images/art_EastCoastDeliMural2.jpg");
  cursor: none;
}
#project31 {
  background-image: url("../images/art_WildThings.jpg");
  cursor: none;
}
#project32 {
  background-image: url("../images/art_ExcavatorTek.jpg");
  cursor: none;
}
#project33 {
  background-image: url("../images/art_Dvine\ Wine\ sign.jpg");
  cursor: none;
}
#project34 {
  background-image: url("../images/art_skateboard.jpg");
  cursor: none;
}
#project35 {
  background-image: url("../images/art_snowboard.jpg");
  cursor: none;
}
#project36 {
  background-image: url("../images/art_hockey.jpg");
  cursor: none;
}
#project37 {
  background-image: url("../images/art_GoskateboardDay.jpg");
  cursor: none;
}
#project38 {
  background-image: url("../images/art_Slavens_Tested.jpg");
  cursor: none;
}
#project39 {
  background-image: url("../images/art_Slavens_CO.jpg");
  cursor: none;
}
#project40 {
  background-image: url("../images/trade_show_10foot_Pitstop.jpg");
  cursor: none;
}
#project41 {
  background-image: url("../images/trade_show_table_top_Lacker.jpg");
  cursor: none;
}
#project42 {
  background-image: url("../images/trade_show_10ft_Chiro.jpg");
  cursor: none;
}
#project43 {
  background-image: url("../images/art_flamingo.jpg");
  cursor: none;
}
#project44 {
  background-image: url("../images/Switchbacks_poster.jpg");
  cursor: none;
}
#project45 {
  background-image: url("../images/Switchbacks_banner.jpg");
  cursor: none;
}
#project46 {
  background-image: url("../images/Switchbacks_ENT_poster.jpg");
  cursor: none;
}
/* Contact form parallax effect image */
.parallax {
  background-image: url("../images/Kc-mural-background.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
}
