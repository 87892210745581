@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
:root {
  --primary-color: rgb(8, 8, 173);
  --hover-color: grey;
}

/* Add your profile image here */
/* .Photo {
  background-image: url("https://images.pexels.com/photos/3799821/pexels-photo-3799821.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
} */
/* Projects Photo */
#project1 {
  background-image: url(/static/media/screenshot-SlavensRacing.8f56e6fc.png);
  cursor: none;
}
#project2 {
  background-image: url(/static/media/screenshot-BRP.53c4096c.png);
  cursor: none;
}
#project3 {
  background-image: url(/static/media/screenshot-ElineAccessories.af9a0050.png);
  cursor: none;
}
#project4 {
  background-image: url(/static/media/screenshot-ExcavatorTek.5960bb31.png);
  cursor: none;
}
#project5 {
  background-image: url(/static/media/screenshot-DSMH.4b7732e1.png);
  cursor: none;
}
#project6 {
  background-image: url(/static/media/weatherdashboard-screenshot.7e008f08.jpg);
  cursor: none;
}
#project7 {
  background-image: url(/static/media/workout-screenshot.30c0d33d.jpg);
  cursor: none;
}
#project8 {
  background-image: url(/static/media/digital_template_engine.ca4db7a5.jpg);
  cursor: none;
}
#project9 {
  background-image: url(/static/media/screenshot-LetsGetTrivial.045f22b2.png);
  cursor: none;
}
#project10 {
  background-image: url("/static/media/Craftsman Tool Packaging.cdef64e8.png");
  cursor: none;
}
#project11 {
  background-image: url("/static/media/Ridgid Tool Packaging.06bda005.png");
  cursor: none;
}
#project12 {
  background-image: url("/static/media/Slavens Racing Magazine Ads.00a2dbc3.png");
  cursor: none;
}
#project13 {
  background-image: url("/static/media/T Shirt Designs.14d64866.png");
  cursor: none;
}
#project14 {
  background-image: url("/static/media/Motorworks Catalog Covers & Ads.b8f3d17c.png");
  cursor: none;
}
#project15 {
  background-image: url("/static/media/MotorWorks Magazine Ads.e660db78.png");
  cursor: none;
}
#project16 {
  background-image: url("/static/media/Motorworks Catalog Inside Pages 2.458802d9.png");
  cursor: none;
}
#project17 {
  background-image: url("/static/media/Motorworks Catalog Inside Pages.6f99431b.png");
  cursor: none;
}
#project18 {
  background-image: url("/static/media/Brochures & Business Cards.90267493.png");
  cursor: none;
}
#project19 {
  background-image: url("/static/media/Display Image Tradeshow Design.9b2bcbd1.png");
  cursor: none;
}
#project20 {
  background-image: url(/static/media/UCCS.09c7417e.gif);
  cursor: none;
}
#project21 {
  background-image: url("/static/media/Tradeshow Design.0c703a13.png");
  cursor: none;
}
#project22 {
  background-image: url(/static/media/trade_show_banners.b002f189.jpg);
  cursor: none;
}
#project23 {
  background-image: url(/static/media/trade_show_20foot.2513785b.jpg);
  cursor: none;
}
#project24 {
  background-image: url(/static/media/trade_show_10foot_FabricPopup.24b00cb3.jpg);
  cursor: none;
}
#project25 {
  background-image: url(/static/media/trade_show_10foot_Sterisil.628a6f03.jpg);
  cursor: none;
}
#project26 {
  background-image: url(/static/media/trade_show_table_top_COS.a2c30353.jpg);
  cursor: none;
}
#project27 {
  background-image: url(/static/media/trade_show_table_top_Goodrich.3d352e27.jpg);
  cursor: none;
}
#project28 {
  background-image: url(/static/media/UncleSamsPiggyBank.d9d08373.jpg);
  cursor: none;
}
/* Miscelaneous Activities photo */
#project29 {
  background-image: url(/static/media/art_EastCoastDeliMural.a038e868.jpg);
  cursor: none;
}
#project30 {
  background-image: url(/static/media/art_EastCoastDeliMural2.c32d1203.jpg);
  cursor: none;
}
#project31 {
  background-image: url(/static/media/art_WildThings.23b0d973.jpg);
  cursor: none;
}
#project32 {
  background-image: url(/static/media/art_ExcavatorTek.3999422f.jpg);
  cursor: none;
}
#project33 {
  background-image: url("/static/media/art_Dvine Wine sign.4175a26f.jpg");
  cursor: none;
}
#project34 {
  background-image: url(/static/media/art_skateboard.e9a2eabe.jpg);
  cursor: none;
}
#project35 {
  background-image: url(/static/media/art_snowboard.08decdf6.jpg);
  cursor: none;
}
#project36 {
  background-image: url(/static/media/art_hockey.6d1fc62e.jpg);
  cursor: none;
}
#project37 {
  background-image: url(/static/media/art_GoskateboardDay.964c52b5.jpg);
  cursor: none;
}
#project38 {
  background-image: url(/static/media/art_Slavens_Tested.d994de31.jpg);
  cursor: none;
}
#project39 {
  background-image: url(/static/media/art_Slavens_CO.6a69959e.jpg);
  cursor: none;
}
#project40 {
  background-image: url(/static/media/trade_show_10foot_Pitstop.c9e398aa.jpg);
  cursor: none;
}
#project41 {
  background-image: url(/static/media/trade_show_table_top_Lacker.1c336b32.jpg);
  cursor: none;
}
#project42 {
  background-image: url(/static/media/trade_show_10ft_Chiro.81827360.jpg);
  cursor: none;
}
#project43 {
  background-image: url(/static/media/art_flamingo.cbba7e7e.jpg);
  cursor: none;
}
#project44 {
  background-image: url(/static/media/Switchbacks_poster.65c807f5.jpg);
  cursor: none;
}
#project45 {
  background-image: url(/static/media/Switchbacks_banner.e54e6cc7.jpg);
  cursor: none;
}
#project46 {
  background-image: url(/static/media/Switchbacks_ENT_poster.831f7aea.jpg);
  cursor: none;
}
/* Contact form parallax effect image */
.parallax {
  background-image: url(/static/media/Kc-mural-background.fb4c118b.jpg);
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  cursor: crosshair;
  background-image: url(/static/media/KC-resume-background.4ca64cad.png);
  background-repeat: no-repeat;
  background-size: contain;
}
#titletext {
  color: white;
  text-shadow: 2px 2px 2px rgb(4, 4, 104);
}
.lead {
  font-weight: 400;
}
.bg-danger {
  background-color: var(--primary-color) !important;
}
.Header {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: Montserrat, sans-serif;
}
.Header h1 {
  color: white;
  text-shadow: 2px 2px 5px rgb(4, 4, 104);
  margin: auto;
  text-align: center;
  font-size: 5.5rem;
  position: absolute;
}
h2 {
  font-size: 21px;
  font-weight: 900;
}
.Header p {
  margin-top: 125px;
}
.particle {
  position: absolute!;
  margin: 0 !important;
  margin-top: -100vh !important;
}
.underline {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--primary-color);
          text-decoration-color: var(--primary-color);
}
.skills {
  padding: 10%;
}
.progress-block {
  padding: 10px 0;
}
.progress-block h2 {
  font-size: x-large;
}
.dark-mode {
  filter: invert(1) hue-rotate(180deg);
  -webkit-filter: invert(1) hue-rotate(180deg);
  -moz-filter: invert(1) hue-rotate(180deg);
}
.inverse-dark {
  filter: invert(1) hue-rotate(180deg);
  -webkit-filter: invert(1) hue-rotate(180deg);
  -moz-filter: invert(1) hue-rotate(180deg);
}
.effect2 {
  position: relative;
  width: 70%;
  background: #fff;
  padding: 5%;
  margin: 10% auto;
}
.effect2:after,
.effect2:before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  box-shadow: 0 15px 10px #777;
  transform: rotate(-3deg);
}
.effect2:after {
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}
.Photo {
  background-color: inherit;
  background-size: contain;
  background-repeat: no-repeat;
}
.Photo.inverse-dark {
  background-color: #000;
}
.about-text {
  width: 80%;
  padding: 2%;
}
.red-line,
.skills-header {
  color: var(--primary-color);
  font-weight: 700;
}
.About-title-box {
  box-sizing: border-box;
  width: 50%;
  padding-bottom: 5px;
  border-bottom: solid 2pxvar (--primary-color);
  margin-bottom: 15px;
}
.third {
  width: 75%;
  margin: auto;
  align-items: center;
  padding: 3%;
  justify-content: center;
}
.project-card {
  padding: 15px;
  margin-top: 15px;
  box-shadow: 0 4px 6px -4px #868686;
}
.project-card h2 {
  color: var(--primary-color);
  margin-top: 10px;
  margin-left: 3%;
  font-weight: 500;
}
.project-card h2:hover {
  color: var(--hover-color);
  text-decoration: none;
}
.project-card h3 {
  text-align: left;
  font-size: 1rem;
  margin-left: 3%;
}
.image-project {
  height: 350px;
  width: auto;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer !important;
}
.img-pro {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s;
}
.image-project:hover .img-pro {
  transform: scale(1.2);
}
.pp-head {
  text-align: center;
}
.pp-head-line {
  box-sizing: border-box;
  width: 30%;
  padding-bottom: 5px;
  border-bottom: solid 2px var(--primary-color);
  margin-bottom: 15px;
}
.pp-text {
  font-size: 1.5rem;
}
.parallax {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.git-form {
  width: 1160px;
  box-sizing: border-box;
  display: block;
  padding: 3rem;
  background-color: #fff;
}
.git-head-div {
  box-sizing: border-box;
  padding-top: 1%;
  padding-bottom: 5px;
  width: 30%;
  border-bottom: 3px solid #000;
  color: #000;
  text-align: center;
}
.git-head {
  font-weight: 700;
}
.phone,
.email {
  margin-top: 15px;
  color: var(--primary-color);
}
.git-cont {
  display: inline-block;
}
.half {
  display: inline-block;
  vertical-align: top;
}
.half form input,
textarea {
  width: 100%;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  outline: 0;
  box-shadow: none;
  border: 1px solid #777;
}
.half form textarea {
  padding-bottom: 45px;
}
.half form input:focus,
textarea:focus {
  border-radius: 5px;
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--primary-color);
}
.half form button {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 2rem;
  color: #fff;
  background-color: var(--primary-color);
  box-shadow: none;
  outline: 0;
  border: none;
  margin-top: 20px;
  margin-left: 20px;
}
.half form button:hover {
  background-color: var(--hover-color);
}
.half form {
  padding: 3rem 1rem;
}
.half p {
  padding: 3rem 1rem 0 1rem;
  text-align: center;
}
.inline-button {
  display: inline;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
}
.fab {
  vertical-align: bottom;
  padding: 10px;
  font-size: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  color: var(--primary-color);
  transition: 0.5;
}
.fab:hover {
  color: var(--hover-color);
}
.fas {
  vertical-align: bottom;
  padding: 25px;
  font-size: 40px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  color: #000;
  transition: 0.5;
}
.navbar {
  width: 100%;
  position: fixed;
  z-index: 1001;
  top: 0;
  box-shadow: 0 8px 6px -6px #646464;
}
.nav-link {
  color: #000 !important;
}
.is-current {
  color: var(--primary-color);
  text-decoration: underline;
  -webkit-text-decoration-style: solid 3px;
          text-decoration-style: solid 3px;
  -webkit-text-decoration-color: var(--primary-color);
          text-decoration-color: var(--primary-color);
}
.Copy {
  color: white !important;
  font-weight: 700;
  background-image: linear-gradient(grey, black);
  border-radius: 20px;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  text-shadow: 2px 2px 2px black;
}
.project-name {
  text-decoration: none;
}
.gtp {
  position: fixed;
  left: 89%;
  height: 150px;
  top: 82%;
  z-index: 1002;
}
.see {
  background-image: linear-gradient(rgb(187, 8, 8), black);
  color: #fff;
  border: #000;
  outline: #000;
  position: absolute;
  padding: 5px 15px;
  padding-bottom: 7px;
  border-radius: 10px;
  left: 44%;
  top: 90%;
}
.fa-eye {
  color: #fff;
  font-size: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 7px;
}
.see:active {
  outline: 0;
}
.see:hover {
  background-image: linear-gradient(rgb(250, 191, 41), black);
  color: black;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  left: 44.5%;
  bottom: 46%;
}
.Header p {
  margin-top: 40px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(187, 8, 8);
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #000;
}
input:focus + .slider {
  box-shadow: 0 0 1px rgb(6, 224, 17);
}
input:checked + .slider:before {
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.line-1 {
  position: relative;
  top: 10%;
  width: 12em;
  margin: 0 auto;
  border-right: 2px solid blue;
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  font-family: "Anonymous Pro", monospace !important;
}
.anim-typewriter {
  animation: typewriter 4s steps(27) 1s 1 normal both,
    blinkTextCursor 0.5s steps(27) infinite normal;
}
.row {
  background-image: linear-gradient(white, grey);
  padding-bottom: 0px;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 20em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(25, 25, 25, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
::selection {
  color: white;
  background: var(--primary-color);
}
@media (max-width: 800px) {
  .lead {
    font-weight: 400;
    text-align: left;
  }
  .Header h1 {
    font-size: 2.5rem;
    margin-top: -310px;
  }
  .line-1 {
    display: none;
    color: white;
    font-size: 80%;
    top: 5.5%;
  }
  .underline {
    -webkit-text-decoration-color: (0, 0, 0, 0.1);
            text-decoration-color: (0, 0, 0, 0.1);
  }
  .about-text {
    width: 100%;
    text-align: center;
  }
  .red-line {
    width: 200px;
    align-content: center;
  }
  /* .red-line::before {
    bottom: 90px;
  } */
  .tsparticles-canvas-el {
    margin-bottom: -500px;
  }
  .effect2 {
    width: 100%;
  }
  .skills {
    padding: 10% 5%;
  }
  .third {
    width: 100%;
  }
  .project-card {
    width: 100% !important;
  }
  .image-project {
    width: 100% !important;
  }
  .third {
    padding: 5%;
  }
  .pp-head-line {
    width: 70%;
  }
  .git-form {
    width: 90%;
    padding: 5px;
    padding-bottom: 35px;
  }
  .git-head-div {
    width: 75%;
  }
  .switch {
    bottom: 48%;
    left: 35%;
  }
  .half form input,
  textarea {
    margin: 5px 0;
    padding: 10px;
  }
  .half p {
    display: none;
  }
  .gtp {
    left: 83%;
    top: 87%;
    height: 75px;
  }
  .Copy {
    display: none;
  }
  .see {
    left: 30%;
    font-size: 1rem;
  }
  .fa-eye {
    font-size: 20px;
  }
  .row {
    background-image: linear-gradient(white, white, grey);
  }
}

